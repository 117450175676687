<template>
  <div @click="handleTabs($event)">
    <template v-for="item in list">
      <span :id="item.id" :class="id===item.id?'active':''" :key="item.id">{{item.factoryName}}</span>
    </template>
  </div>
</template>

<script>
import { selectFactoryList } from '@/api'
export default {

  data() {
    return {
      id: null,
      list: []
    }
  },
  mounted() {
    this.selectFactoryList()
  },
  methods: {
    handleTabs(e) {
      if (!e.target.id) {
        return false
      }
      this.id = e.target.id
      // this.$parent.factoryId = e.target.id;
      // this.$parent.factoryInfo = null
      this.$emit('selectfactoryId',e.target.id);
      // this.$parent.selectFactoryModular()
    },
    async selectFactoryList() {
      const { data } = await selectFactoryList()
      if (data && data.code === 0) {
        this.list = data.data
        this.id = data.data[0].id
        // this.$parent.factoryId = data.data[0].id
        // this.$parent.selectFactoryModular()
        this.$emit('selectfactoryId',data.data[0].id);
      }
    }
  },

}
</script>

<style lang="scss" scoped>
div {
  display: flex;
  span {
    font-size: 14px;
    color: #000;
    border: 1px solid #d9d9d9;
    height: 32px;
    line-height: 32px;
    display: block;
    padding: 0 10px;
    cursor: pointer;
    transition: all 0.5;
    &.active {
      border: 1px solid #1890ff;
      box-shadow: 0px 0px 6px rgba(24, 144, 255, 0.23);
      opacity: 1;
      border-radius: 1px 0px 0px 1px;
      font-weight: 500;
      color: #1890ff;
    }
  }
}
</style>